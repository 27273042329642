export default () => ({
    routes: {
        'wifi-finder': {
            path: '/wifi',
            area: 'wifi-finder',
            app: 'wifi-finder',
            tracking: {
                root: ['wifi-finder'],
                pagecrumb: () => 'home'
            },
            allowDefaultPolarisTrack: {
                onMount: () => false,
                onUpdate: () => false
            },
            mastheadVersion: 'help-and-support',
            mastheadUser: 'helpandsupport'
        }
    }
});
