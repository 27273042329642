import React from 'react';
import { hot } from 'react-hot-loader';
import { Button, Icon } from '@sky-uk/toolkit-react';
import wifiIcon from '@sky-uk/skycons/dist/linearWifi';
import heartIcon from '@sky-uk/skycons/dist/linearHeart';
import accountIcon from '@sky-uk/skycons/dist/linearAccount';
import step1Icon from '@sky-uk/skycons/dist/linearNumber1';
import step2Icon from '@sky-uk/skycons/dist/linearNumber2';
import step3Icon from '@sky-uk/skycons/dist/linearNumber3';
import step4Icon from '@sky-uk/skycons/dist/linearNumber4';
import Header from './header';
import LinkSection from './links-section';
import gradients from '../constants/gradients';
import Tracking from 'client/tracking';

if (process.env.WEBPACK_BUILD) {
    require('../stylesheets/wifi-finder.scss');
}

class LandingPage extends React.PureComponent {
    componentDidMount() {
        document.title = 'Sky WiFi - Connect to the Cloud | Sky.com';
        Tracking.pageLoad({
            area: 'wifi-finder',
            locationParams: {
                customPageName: 'landing-page'
            }
        });
    }

    render() {
        return (
            <div className="app-wifi-finder">
                <Header>
                    <h1>
                        <span className="c-heading-delta">Autoconnect to</span><br />
                        <span className="c-heading-bravo">Thousands of <strong>free WiFi hotspots</strong></span>
                    </h1>
                </Header>

                <div className="o-container u-center-until-medium u-margin-top-large">
                    <p className="u-text-center u-margin-bottom-large">With Sky WiFi you can enjoy a fast, <br />reliable connection on the go</p>

                    <div className="o-layout u-text-center u-margin-top-large u-margin-bottom-large">
                        <div className="o-layout__item u-width-1/1 u-width-1/3@medium">
                            <Icon svgSource={wifiIcon} gradient={gradients.ICON} cssClassName="u-margin-bottom" size="50" />
                            <h3 className="u-text-bold u-margin-bottom-small">Connect anywhere</h3>
                            <p>Get online at all the places you love.</p>
                        </div>
                        <div className="o-layout__item u-width-1/1 u-width-1/3@medium">
                            <Icon svgSource={accountIcon} gradient={gradients.ICON} cssClassName="u-margin-bottom" size="50" />
                            <h3 className="u-text-bold u-margin-bottom-small">One login</h3>
                            <p>Sign up once and autoconnect <br /> every time.</p>
                        </div>
                        <div className="o-layout__item u-width-1/1 u-width-1/3@medium">
                            <Icon svgSource={heartIcon} gradient={gradients.ICON} cssClassName="u-margin-bottom" size="50" />
                            <h3 className="u-text-bold u-margin-bottom-small">Browse safely</h3>
                            <p>Stay safe with Sky WiFi by <br />reading <a href="https://www.sky.com/help/articles/sky-wifi-security-tips">our tips</a>.</p>
                        </div>
                    </div>
                    <hr className="c-divider u-margin-top-large u-margin-bottom-large" />
                    <div className="o-layout">
                        <div className="o-layout__item u-width-1/1 u-width-1/2@medium u-margin-top-large u-margin-bottom-large">
                            <h2 className="c-heading-delta">How to connect</h2>
                            <ul className="o-list-bare">
                                <li className="u-margin-bottom">
                                    <Icon svgSource={step1Icon} gradient={gradients.ICON} inline cssClassName="u-margin-right c-icon--number" />
                                    Check your WiFi is on.
                                </li>
                                <li className="u-margin-bottom">
                                    <Icon svgSource={step2Icon} gradient={gradients.ICON} inline cssClassName="u-margin-right c-icon--number" />
                                    Select _The Cloud from the available network list.
                                </li>
                                <li className="u-margin-bottom">
                                    <Icon svgSource={step3Icon} gradient={gradients.ICON} inline cssClassName="u-margin-right c-icon--number" />
                                    Follow the on screen instructions.
                                </li>
                                <li className="u-margin-bottom">
                                    <Icon svgSource={step4Icon} gradient={gradients.ICON} inline cssClassName="u-margin-right c-icon--number" />
                                    You're now connected and will autoconnect in future.
                                </li>
                            </ul>
                        </div>
                        <div className="o-layout__item u-width-1/1 u-width-1/2@medium u-margin-top-large u-margin-bottom-large">
                            <h2 className="c-heading-delta">Need more help?</h2>
                            <Button text="View our help guides" href="https://www.sky.com/help/home/broadband/connecting-to-my-broadband/sky-wifi-connect-to-the-cloud/diagnostics/sky-wifi-help/sky-wifi-what-would-you-like-help-with" modifier="secondary" cssClassName="u-margin-bottom-large" />
                            <h2 className="c-heading-delta">Marketing preference centre</h2>
                            <p>Thinking of reviewing your Wi-Fi marketing preferences?</p>
                            <Button text="Review preferences" href="https://wifi.sky.com/gdpr" modifier="secondary" />
                        </div>
                    </div>
                    <hr className="c-divider u-margin-top-large u-margin-bottom-large" />
                    <div className="o-layout u-margin-top-large">
                        <div className="o-layout__item u-width-1/1 u-width-1/2@medium u-margin-top-large">
                            <h2 className="c-heading-delta">Are you a business looking for WiFi?</h2>
                            <p>To get more information and find out how we can help email us at connectivity@sky.uk</p>
                        </div>
                        <div className="o-layout__item u-width-1/1 u-width-1/2@medium u-margin-top-large">
                            <LinkSection />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default hot(module)(LandingPage);
