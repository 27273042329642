import React from 'react';
import { Link } from 'react-router';

export default class SkyWifiLogo extends React.PureComponent {
    render() {
        return (
            <Link to="/wifi">
                <svg className="c-sky-wifi-logo" version="1.1" id="Artwork" x="0px" y="0px" viewBox="0 0 580 212">
                    <g>
                        <path d="M323.1,127.9l-0.3,0c0-0.3,0-0.5,0-0.8c0-7.7-6.3-14-14-14c-6.4,0-11.7,4.3-13.4,10.1c-1.6-1.5-3.6-2.4-6-2.4
                            c-4.4,0-8,3.3-8.6,7.6c-6.1,0.7-10.8,5.9-10.8,12.1c0,6.8,5.5,12.2,12.3,12.2c2.7,0,5.1-0.9,7.1-2.3c2.9,3.3,7.1,5.5,11.9,5.5
                            c4.2,0,7.9-1.6,10.7-4.3L321,162l-1.9-10.8c1.2,0.4,2.6,0.7,4,0.7c6.6,0,12-5.4,12-12C335,133.3,329.7,127.9,323.1,127.9z" />
                        <polygon points="341.6,129.1 350,129.1 350,154.6 355.5,154.6 355.5,129.1 363.2,129.1 364.8,124.7 341.6,124.7" />
                        <path d="M380.3,132.6c-2.8,0-5.1,1.2-7.1,2.8v-10.8h-5.5v29.9h5.5v-15c1.4-1.2,3.4-2.3,5.2-2.3c2.9,0,3.3,2.3,3.3,4.7
                            v12.6h5.5v-14.1C387.1,134.2,383.6,132.6,380.3,132.6z" />
                        <path d="M400.6,132.6c-6.1,0-9.8,5.1-9.8,11.2c0,5.8,3.2,11.2,10.7,11.2c2.6,0,5.4-0.7,7.8-2.4l-1.3-3.5
                            c-1.9,1-3.5,1.5-5.6,1.5c-3.3,0-5.9-2.2-6-5.4h13.9v-1.6C410.2,137.1,406.4,132.6,400.6,132.6z M396.2,141.5c0-2.4,1.6-4.7,4.2-4.7
                            c2.8,0,4,2.4,4.1,4.7H396.2z" />
                        <path d="M437.8,150.3c-6.2,0-10-4.7-10-10.7c0-5.6,3.5-10.7,10.4-10.7c2.7,0,5.1,0.6,7.6,2.1l0-5
                            c-1.8-1-4.6-1.6-7.8-1.6c-9.9,0-16.2,7-16.2,15.6c0,8.5,5.5,15.1,15.1,15.1c3.2,0,6.4-0.8,9.7-2.8l-1.5-3.9
                            C442.9,149.5,440.6,150.3,437.8,150.3z" />
                        <path d="M457.8,150.2c-1.1,0-1.5-0.6-1.5-2.2v-23.3h-5.4v25.7c0,2.7,1.4,4.7,4.9,4.7c1.2,0,2.6-0.2,4.2-1.1l-0.7-4
                            C458.6,150.1,458.2,150.2,457.8,150.2z" />
                        <path d="M472.7,132.6c-3.1,0-5.7,1.1-7.6,3c-2,2.1-3.1,5-3.1,8.3c0,3.4,1.2,6.4,3.3,8.4c1.9,1.7,4.4,2.7,7.4,2.7
                            c3.2,0,5.9-1.1,7.8-3.1c2-2,3-4.9,3-8.2c0-3.3-1-6.1-2.9-8.1C478.7,133.8,476,132.6,472.7,132.6z M472.7,150.9
                            c-3.3,0-5.3-2.8-5.3-7c0-4,1.7-6.9,5.1-6.9c3.4,0,5.3,2.8,5.3,6.9C477.8,147.9,476.1,150.9,472.7,150.9z" />
                        <path d="M524.5,124.7v8.1c-0.8-0.1-1.6-0.2-2.7-0.2c-6.9,0-11.9,4.7-11.9,11.4c0,7.3,5.3,11,11.7,11
                            c3.7,0,6.4-0.7,8.4-1.7v-28.7H524.5z M524.5,150.3c-0.6,0.2-1.6,0.3-2.5,0.3c-4.2,0-6.7-2.9-6.7-6.8c0-4.2,2.8-6.8,6.6-6.8
                            c1.2,0,1.8,0.1,2.6,0.3V150.3z" />
                        <path d="M500.7,145.9c0,1,0,1.9-0.2,2.6c-0.6,1.6-2,2.3-3.8,2.3c-1.8,0-3.2-0.9-3.8-2.5c-0.2-0.6-0.2-1.4-0.2-2.3
                            v-12.3H487v11.3c0,3,0.1,5.3,2.2,7.6c1.6,1.8,4.4,2.6,7.4,2.6v0c3.1,0,5.8-0.8,7.4-2.6c2.1-2.3,2.2-4.5,2.2-7.6v-11.3h-5.6V145.9z"
                        />
                        <path d="M71.9,151.2c-0.3,0-0.6,0.1-1,0.2c-0.4,0.2-0.9,0.3-1.5,0.5c-0.6,0.2-1.3,0.4-2.1,0.5
                            c-0.8,0.2-1.7,0.2-2.7,0.2c-1.5,0-3-0.3-4.4-0.8c-1.4-0.5-2.6-1.3-3.6-2.4c-1-1.1-1.8-2.4-2.4-3.9c-0.6-1.5-0.9-3.3-0.9-5.3
                            c0-1.9,0.3-3.6,0.9-5.2c0.6-1.5,1.4-2.8,2.4-3.9c1-1.1,2.2-1.9,3.6-2.5c1.4-0.6,2.8-0.9,4.4-0.9c1,0,1.9,0.1,2.7,0.2
                            c0.8,0.2,1.5,0.3,2.1,0.5c0.6,0.2,1.1,0.4,1.5,0.5c0.4,0.2,0.7,0.2,1,0.2c0.4,0,0.8-0.2,1.1-0.5c0.3-0.4,0.4-1,0.3-2
                            c-1.3-0.5-2.6-1-4.1-1.3s-3-0.6-4.6-0.6c-2.2,0-4.2,0.4-6,1.2c-1.8,0.8-3.3,1.9-4.6,3.3c-1.3,1.4-2.2,3-3,4.9
                            c-0.7,1.9-1.1,3.9-1.1,6.1c0,2.2,0.4,4.3,1.1,6.1c0.7,1.9,1.7,3.5,3,4.9c1.3,1.4,2.8,2.4,4.6,3.2s3.8,1.1,6,1.1
                            c1.6,0,3.1-0.2,4.6-0.6c1.5-0.4,2.9-0.8,4.1-1.3c0.1-1,0-1.7-0.3-2C72.7,151.4,72.3,151.2,71.9,151.2z" />
                        <path d="M94.7,136c-1-1-2.1-1.9-3.4-2.5c-1.3-0.6-2.8-0.9-4.5-0.9c-1.7,0-3.2,0.3-4.5,0.9c-1.4,0.6-2.5,1.4-3.5,2.5
                            c-1,1.1-1.7,2.3-2.2,3.7c-0.5,1.4-0.8,2.8-0.8,4.4c0,1.6,0.3,3.1,0.8,4.5c0.5,1.4,1.3,2.6,2.2,3.6c1,1,2.1,1.8,3.5,2.4
                            c1.4,0.6,2.9,0.9,4.5,0.9c1.7,0,3.2-0.3,4.5-0.9c1.3-0.6,2.5-1.4,3.4-2.5c0.9-1,1.7-2.3,2.2-3.6c0.5-1.4,0.8-2.8,0.8-4.4
                            c0-1.5-0.3-3-0.8-4.4C96.4,138.3,95.7,137.1,94.7,136z M93.9,148c-0.5,1.1-1.1,2-1.8,2.7c-0.7,0.7-1.6,1.3-2.5,1.6
                            c-0.9,0.4-1.8,0.5-2.8,0.5c-1,0-2-0.2-2.9-0.5c-1-0.4-1.8-0.9-2.6-1.6c-0.8-0.7-1.4-1.7-1.8-2.7c-0.5-1.1-0.7-2.4-0.7-3.9
                            c0-1.4,0.2-2.6,0.7-3.7c0.5-1.1,1.1-2,1.8-2.8c0.8-0.8,1.6-1.3,2.6-1.7c1-0.4,1.9-0.6,2.9-0.6c1,0,2,0.2,3,0.6
                            c0.9,0.4,1.8,1,2.5,1.7c0.7,0.8,1.3,1.7,1.7,2.8c0.4,1.1,0.6,2.3,0.6,3.7C94.6,145.6,94.4,146.9,93.9,148z" />
                        <path d="M112.9,132.6c-1.7,0-3.2,0.3-4.4,1c-1.2,0.7-2.3,1.6-3.1,2.7h-0.1V135c0-1.4-0.6-2-1.8-2
                            c-0.4,0-0.8,0.1-1.3,0.2v22c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.8,0c0.6,0,1-0.1,1.3-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-14.8
                            c0.3-0.6,0.7-1.2,1.2-1.7c0.5-0.5,1-0.9,1.6-1.3c0.6-0.4,1.2-0.6,1.9-0.8c0.7-0.2,1.3-0.3,1.9-0.3c1.8,0,3.3,0.6,4.4,1.7
                            c1.1,1.1,1.6,2.9,1.6,5.3v12.9c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.8,0c0.6,0,1-0.1,1.3-0.3c0.2-0.2,0.3-0.5,0.3-0.9V142
                            c0-2.8-0.7-5.1-2.1-6.8C117.6,133.5,115.6,132.6,112.9,132.6z" />
                        <path d="M137.9,132.6c-1.7,0-3.2,0.3-4.4,1c-1.2,0.7-2.3,1.6-3.1,2.7h-0.1V135c0-1.4-0.6-2-1.8-2
                            c-0.4,0-0.8,0.1-1.3,0.2v22c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.8,0c0.6,0,1-0.1,1.3-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-14.8
                            c0.3-0.6,0.7-1.2,1.2-1.7c0.5-0.5,1-0.9,1.6-1.3c0.6-0.4,1.2-0.6,1.9-0.8c0.7-0.2,1.3-0.3,1.9-0.3c1.8,0,3.3,0.6,4.4,1.7
                            c1.1,1.1,1.6,2.9,1.6,5.3v12.9c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.8,0c0.6,0,1-0.1,1.3-0.3c0.2-0.2,0.3-0.5,0.3-0.9V142
                            c0-2.8-0.7-5.1-2.1-6.8C142.6,133.5,140.5,132.6,137.9,132.6z" />
                        <path d="M168.7,136.1c-0.8-1-1.9-1.9-3.1-2.5c-1.3-0.7-2.7-1-4.4-1c-1.5,0-2.9,0.3-4.1,0.9c-1.3,0.6-2.4,1.4-3.4,2.4
                            c-1,1-1.7,2.2-2.2,3.6c-0.5,1.4-0.8,2.9-0.8,4.6c0,1.6,0.3,3.1,0.8,4.5s1.2,2.6,2.2,3.6c1,1,2.2,1.9,3.6,2.4c1.4,0.6,3,0.9,4.8,0.9
                            c1.7,0,3.2-0.2,4.6-0.6c1.4-0.4,2.5-0.9,3.3-1.3c0.1-0.7,0-1.2-0.2-1.6c-0.3-0.4-0.6-0.6-1.1-0.6c-0.3,0-0.7,0.1-1.1,0.2
                            c-0.4,0.2-0.9,0.3-1.4,0.5c-0.5,0.2-1.2,0.4-1.8,0.5c-0.7,0.2-1.5,0.2-2.4,0.2c-1.1,0-2.1-0.2-3.1-0.5c-1-0.3-1.8-0.8-2.6-1.5
                            c-0.7-0.7-1.3-1.5-1.8-2.4c-0.4-1-0.7-2.1-0.8-3.3H171c0.1-0.4,0.1-1,0.1-2c0-1.1-0.2-2.3-0.6-3.6
                            C170.1,138.3,169.5,137.2,168.7,136.1z M153.7,142.8c0.1-1.1,0.3-2.1,0.7-3c0.4-0.9,0.9-1.7,1.6-2.4c0.6-0.7,1.4-1.2,2.3-1.6
                            c0.9-0.4,1.8-0.6,2.9-0.6c1.1,0,2,0.2,2.8,0.6c0.8,0.4,1.6,0.9,2.2,1.6c0.6,0.7,1.1,1.5,1.4,2.4c0.4,0.9,0.6,1.9,0.6,3H153.7z" />
                        <path d="M191.1,151.4c-0.2,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.7,0.3-1.1,0.5c-0.4,0.2-1,0.3-1.6,0.5
                            c-0.6,0.1-1.4,0.2-2.2,0.2c-1.1,0-2.1-0.2-3.1-0.6c-1-0.4-1.8-0.9-2.5-1.7c-0.7-0.7-1.3-1.6-1.7-2.7c-0.4-1.1-0.6-2.3-0.6-3.6
                            c0-1.4,0.2-2.6,0.6-3.7c0.4-1.1,1-2,1.7-2.7c0.7-0.7,1.6-1.3,2.5-1.7c1-0.4,2-0.6,3.1-0.6c0.8,0,1.5,0.1,2.1,0.2s1.1,0.3,1.5,0.4
                            c0.4,0.2,0.7,0.3,1,0.4c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.2,1.1-0.6c0.3-0.4,0.4-1,0.3-1.7c-0.9-0.5-1.9-0.9-3-1.2
                            c-1.1-0.3-2.4-0.5-4-0.5c-1.7,0-3.2,0.3-4.5,0.9c-1.4,0.6-2.5,1.4-3.5,2.4s-1.7,2.2-2.2,3.6c-0.5,1.4-0.8,2.9-0.8,4.4
                            c0,1.6,0.3,3,0.8,4.4c0.5,1.4,1.2,2.6,2.2,3.6c1,1,2.1,1.8,3.5,2.4s2.9,0.9,4.5,0.9c0.8,0,1.6,0,2.2-0.1c0.7-0.1,1.3-0.2,1.9-0.3
                            c0.6-0.2,1.1-0.3,1.7-0.6s1-0.5,1.6-0.7c0.1-0.8,0-1.3-0.3-1.7C191.9,151.6,191.6,151.4,191.1,151.4z" />
                        <path d="M209.1,151.5c-0.2,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.8,0.3-1.2,0.4
                            c-0.4,0.1-0.9,0.2-1.5,0.2c-1.4,0-2.5-0.4-3.1-1.2c-0.7-0.8-1-1.9-1-3.3v-12.4h7.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-1.1
                            c0-0.5,0-0.9-0.1-1.2h-8.4v-6.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0c-0.8,0-1.3,0.2-1.5,0.6c-0.2,0.4-0.3,0.8-0.3,1.2v4.5
                            h-2.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,1.1c0,0.5,0,0.9,0.1,1.2h3.6v12.6c0,2.1,0.6,3.8,1.8,5.1c1.2,1.3,2.9,2,5.1,2
                            c1.2,0,2.3-0.2,3.3-0.5c1-0.3,1.9-0.7,2.7-1.3c0.1-0.7,0-1.3-0.2-1.6C209.9,151.7,209.6,151.5,209.1,151.5z" />
                        <path d="M236.1,151.5c-0.2,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.8,0.3-1.2,0.4
                            c-0.4,0.1-0.9,0.2-1.5,0.2c-1.4,0-2.5-0.4-3.1-1.2c-0.7-0.8-1-1.9-1-3.3v-12.4h7.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-1.1
                            c0-0.5,0-0.9-0.1-1.2h-8.4v-6.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0c-0.8,0-1.3,0.2-1.5,0.6c-0.2,0.4-0.3,0.8-0.3,1.2v4.5
                            h-2.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,1.1c0,0.5,0,0.9,0.1,1.2h3.6v12.6c0,2.1,0.6,3.8,1.8,5.1c1.2,1.3,2.9,2,5.1,2
                            c1.2,0,2.3-0.2,3.3-0.5c1-0.3,1.9-0.7,2.7-1.3c0.1-0.7,0-1.3-0.2-1.6C236.8,151.7,236.5,151.5,236.1,151.5z" />
                        <path d="M258.5,136c-1-1-2.1-1.9-3.4-2.5c-1.3-0.6-2.8-0.9-4.5-0.9c-1.7,0-3.2,0.3-4.5,0.9c-1.4,0.6-2.5,1.4-3.5,2.5
                            c-1,1.1-1.7,2.3-2.2,3.7c-0.5,1.4-0.8,2.8-0.8,4.4c0,1.6,0.3,3.1,0.8,4.5c0.5,1.4,1.3,2.6,2.2,3.6s2.1,1.8,3.5,2.4
                            c1.4,0.6,2.9,0.9,4.5,0.9c1.7,0,3.2-0.3,4.5-0.9c1.3-0.6,2.5-1.4,3.4-2.5c0.9-1,1.7-2.3,2.2-3.6c0.5-1.4,0.8-2.8,0.8-4.4
                            c0-1.5-0.3-3-0.8-4.4C260.2,138.3,259.4,137.1,258.5,136z M257.7,148c-0.5,1.1-1.1,2-1.8,2.7c-0.7,0.7-1.6,1.3-2.5,1.6
                            c-0.9,0.4-1.8,0.5-2.8,0.5c-1,0-2-0.2-2.9-0.5c-1-0.4-1.8-0.9-2.6-1.6c-0.8-0.7-1.4-1.7-1.8-2.7c-0.5-1.1-0.7-2.4-0.7-3.9
                            c0-1.4,0.2-2.6,0.7-3.7c0.5-1.1,1.1-2,1.8-2.8c0.8-0.8,1.6-1.3,2.6-1.7c1-0.4,1.9-0.6,2.9-0.6c1,0,2,0.2,3,0.6
                            c0.9,0.4,1.8,1,2.5,1.7c0.7,0.8,1.3,1.7,1.7,2.8c0.4,1.1,0.6,2.3,0.6,3.7C258.4,145.6,258.2,146.9,257.7,148z" />
                        <path d="M195.6,93.5c-0.9,0.8-2.1,1.5-3.6,1.9c-1.5,0.5-3.3,0.7-5.3,0.7c-1.8,0-3.3-0.1-4.7-0.3
                            c-1.4-0.2-2.6-0.5-3.6-0.7c-1.1-0.3-2-0.5-2.7-0.7c-0.8-0.2-1.5-0.3-2.1-0.3c-0.9,0-1.7,0.3-2.2,1c-0.5,0.7-0.8,1.6-0.7,2.8
                            c2.8,1,5.5,1.7,8.1,2.2c2.6,0.4,5,0.6,7.3,0.6c3,0,5.6-0.3,7.8-1c2.2-0.7,4-1.6,5.4-2.8c1.4-1.2,2.5-2.6,3.2-4.3
                            c0.7-1.7,1.1-3.6,1.1-5.6c0-3.1-1-5.7-2.9-7.8c-1.9-2.1-4.9-3.8-9-5l-6.7-1.9c-3.4-0.9-5.7-2-7-3.3c-1.3-1.2-1.9-2.9-1.9-4.9
                            c0-0.9,0.2-1.8,0.5-2.7c0.3-1,0.9-1.8,1.8-2.6c0.8-0.8,1.9-1.4,3.3-1.9c1.4-0.5,3.1-0.7,5.1-0.7c1.6,0,3.1,0.1,4.3,0.3
                            c1.3,0.2,2.4,0.5,3.3,0.7c1,0.2,1.8,0.5,2.5,0.7c0.7,0.2,1.3,0.3,1.8,0.3c0.9,0,1.7-0.3,2.2-1c0.5-0.7,0.8-1.6,0.7-2.8
                            c-2.6-1-5.1-1.7-7.4-2.1c-2.3-0.4-4.7-0.6-7.1-0.6c-3,0-5.6,0.4-7.6,1.2c-2,0.8-3.7,1.8-4.9,3c-1.2,1.2-2.1,2.5-2.6,4
                            c-0.5,1.5-0.8,2.9-0.8,4.4c0,1.5,0.2,2.8,0.7,4.1c0.4,1.3,1.1,2.5,2.1,3.6c1,1.1,2.2,2.1,3.7,3c1.5,0.9,3.4,1.6,5.7,2.3l6.6,1.9
                            c2.6,0.7,4.7,1.7,6.3,2.9c1.6,1.2,2.3,3,2.3,5.5c0,1.2-0.2,2.3-0.7,3.4C197.2,91.7,196.6,92.7,195.6,93.5z" />
                        <path d="M221.4,81.7L239,65.2c-0.4-0.1-0.9-0.2-1.4-0.3c-0.6-0.1-1.2-0.2-1.9-0.2c-0.9,0-1.7,0.1-2.3,0.4
                            c-0.6,0.2-1.1,0.6-1.5,1L216.1,81V50.4c-0.2-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.9-0.1-1.3-0.1c-1,0-1.6,0.2-2,0.5
                            c-0.4,0.4-0.5,0.8-0.5,1.4v48c0.2,0.1,0.6,0.2,1,0.2c0.4,0,0.9,0.1,1.3,0.1c1,0,1.6-0.2,2-0.5s0.5-0.8,0.5-1.4V82.5l16.6,16
                            c0.5,0.5,1,0.9,1.6,1.1c0.6,0.2,1.4,0.4,2.3,0.4c0.8,0,1.5-0.1,2-0.2c0.6-0.1,1.1-0.3,1.5-0.4L221.4,81.7z" />
                        <path d="M249.9,113.4c0.4,0.1,0.8,0.3,1.3,0.4c0.5,0.1,1,0.2,1.4,0.2c0.5,0,1.1-0.1,1.6-0.4c0.5-0.3,0.9-0.8,1.2-1.5
                            l18.5-46.6c-0.4-0.1-0.8-0.3-1.3-0.4c-0.5-0.1-1-0.2-1.4-0.2c-0.5,0-1.1,0.1-1.6,0.4c-0.5,0.3-0.9,0.8-1.2,1.5l-10.5,27.6h-0.1
                            l-10.7-27.6c-0.3-0.8-0.7-1.3-1.2-1.6c-0.5-0.3-1.2-0.4-2-0.4c-0.5,0-0.9,0.1-1.4,0.2c-0.4,0.1-0.8,0.3-1.2,0.4l14.2,34.7
                            L249.9,113.4z" />
                        <path d="M347.2,52.2c-0.4-0.1-0.9-0.2-1.4-0.2c-1.5,0-2.3,0.6-2.6,1.8l-5.1,23.4c-0.3,1.3-0.6,2.8-0.9,4.3
                            c-0.3,1.6-0.6,3.2-1,4.8c-0.3,1.6-0.7,3.2-1,4.8c-0.3,1.6-0.6,3.1-0.9,4.4h-0.1c-0.7-2.8-1.4-5.7-2.2-8.7c-0.8-3-1.6-6.1-2.5-9.2
                            l-6.7-23.1c-0.3-1-0.7-1.6-1.1-2c-0.5-0.4-1.3-0.5-2.6-0.5c-0.9,0-1.8,0.1-2.9,0.4l-7.2,25.2c-1,3.3-1.8,6.4-2.5,9.2
                            c-0.7,2.8-1.4,5.7-2.2,8.7h-0.1c-0.6-3-1.2-5.9-1.8-8.8c-0.6-2.9-1.2-5.8-1.8-8.8L295.3,54c-0.1-0.7-0.5-1.2-1-1.5
                            c-0.5-0.3-1.1-0.4-1.8-0.4c-0.5,0-1,0.1-1.5,0.2c-0.4,0.1-0.8,0.3-1.2,0.4l10.8,47.2c0.4,0.2,1,0.3,1.8,0.4
                            c0.7,0.1,1.3,0.1,1.8,0.1c1,0,1.8-0.2,2.4-0.5c0.5-0.3,0.9-1,1.2-1.9l7.8-27.1c0.5-1.8,1.1-3.9,1.7-6.2c0.6-2.3,1.2-4.6,1.7-7h0.1
                            c0.5,2.2,1.1,4.6,1.7,7c0.6,2.4,1.2,4.4,1.6,6.2l8.3,29c0.4,0.1,1,0.3,1.6,0.4c0.6,0.1,1.2,0.1,1.7,0.1c1,0,1.9-0.1,2.5-0.4
                            c0.7-0.3,1.1-0.9,1.4-1.9l10.2-45.4C348,52.5,347.6,52.4,347.2,52.2z" />
                        <path d="M357.6,56.4c0.9,0,1.6-0.3,2.2-1c0.6-0.6,0.9-1.4,0.9-2.2c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                            c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C355.9,56.1,356.7,56.4,357.6,56.4z" />
                        <path d="M357.6,64.8c-1,0-1.6,0.2-2,0.6s-0.5,0.8-0.5,1.4V100c0.2,0.1,0.6,0.2,1,0.2c0.4,0,0.9,0.1,1.3,0.1
                            c1,0,1.6-0.2,2-0.5s0.5-0.8,0.5-1.4V65.1c-0.2-0.1-0.6-0.2-1-0.2C358.5,64.8,358,64.8,357.6,64.8z" />
                        <path d="M373.6,100.3c1,0,1.7-0.2,2.1-0.5c0.4-0.4,0.6-0.8,0.6-1.4V78.7H396c0.5,0,1-0.2,1.4-0.5
                            c0.4-0.4,0.5-1,0.5-1.9c0-0.4,0-0.9-0.1-1.2c0-0.4-0.1-0.7-0.2-1h-21.3V56.7h21.5c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.6-0.9,0.6-1.9
                            c0-0.4,0-0.8-0.1-1.2c0-0.4-0.1-0.7-0.2-1h-26.1c-1,0-1.6,0.2-1.9,0.6c-0.3,0.4-0.4,0.9-0.4,1.5V100c0.2,0.1,0.6,0.2,1,0.2
                            C372.7,100.2,373.2,100.3,373.6,100.3z" />
                        <path d="M408.6,50.1c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,1,2.2,1
                            c0.9,0,1.6-0.3,2.2-1c0.6-0.6,0.9-1.4,0.9-2.2c0-0.9-0.3-1.6-0.9-2.2C410.3,50.5,409.5,50.1,408.6,50.1z" />
                        <path d="M408.6,64.8c-1,0-1.6,0.2-2,0.6s-0.5,0.8-0.5,1.4V100c0.2,0.1,0.6,0.2,1,0.2c0.4,0,0.9,0.1,1.3,0.1
                            c1,0,1.6-0.2,2-0.5s0.5-0.8,0.5-1.4V65.1c-0.2-0.1-0.6-0.2-1-0.2S409.1,64.8,408.6,64.8z" />
                    </g>
                </svg>
            </Link>
        );
    }
}
