import React from 'react';
import SkyWifiIcon from './sky-wifi-logo';

export default class Header extends React.PureComponent {
    render() {
        return (
            <div className="c-header u-padding-top-large u-padding-bottom-large u-margin-bottom-large u-text-center">
                <div className="o-container">
                    <SkyWifiIcon />
                    {this.props.children}
                </div>
            </div>
        );
    }
}
