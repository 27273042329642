import React from 'react';
import { Route, IndexRoute } from 'react-router';
import LandingPage from '../components/landingpage';

const getRouteComponent = (path) => {
    return (
        <Route path={path}>
            <IndexRoute component={LandingPage} />
        </Route>
    );
};

export default getRouteComponent;
