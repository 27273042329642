import React from 'react';

export default class LinkSection extends React.PureComponent {
    render() {
        return (
            <div className="c-links-section">
                <h2 className="c-heading-delta">More on Sky WiFi</h2>
                <ul className="o-layout">
                    <li className="o-layout__item u-width-1/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://www.sky.com/help/articles/sky-wifi-about-us" className="c-footer-link c-link c-text">
                                About Sky WiFi
                        </a>
                    </li>
                    <li className="o-layout__item u-width-2/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://businessconnect.sky.com" className="c-footer-link c-link c-text">
                                Get Sky WiFi for Business
                        </a>
                    </li>
                    <li className="o-layout__item u-width-1/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://www.sky.com/help/articles/sky-wifi-complaints" className="c-footer-link c-link c-text">
                                Complaints
                        </a>
                    </li>
                    <li className="o-layout__item u-width-2/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://www.sky.com/help/articles/sky-wifi-privacy-and-cookie-policy" className="c-footer-link c-link c-text">
                                Sky WiFi Privacy & Cookie Policy
                        </a>
                    </li>
                    <li className="o-layout__item u-width-1/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://www.sky.com/help/articles/sky-wifi-contact-us" className="c-footer-link c-link c-text">
                                Contact Us
                        </a>
                    </li>
                    <li className="o-layout__item u-width-2/3@large u-padding-bottom-small u-width-1/1@small">
                        <a href="https://www.sky.com/help/articles/terms-and-conditions-for-sky-wifi" className="c-footer-link c-link c-text">
                                Sky WiFi Terms & Conditions
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}
